import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ['element']

    submit (event) {
        if (event.params.submitter) {
            this.formElement.requestSubmit(document.querySelector(event.params.submitter));
        } else {
            this.formElement.requestSubmit();
        }
    }

    get formElement () {
        return (this.hasElementTarget ? this.elementTarget : this.element);
    }
}
