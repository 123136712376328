import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static targets = [
        'singlePriceGross',
        'amount',
        'quantityPriceGross',

        'pagesAmount',
        'pagesPriceGross',

        'bagsAmount',
        'bagsPriceGross',
        'pergamentBagAmount',
        'pergamentBagPriceGross',
        'stickerAmount',
        'stickerPriceGross',
        'rulerAmount',
        'rulerPriceGross',
        'extraLogo',
        'logoPriceGross',
        'calendarHolidaysPriceGross',
        'calendarDatesPriceGross',
        'earlyBirdRebateGross',
        'addedPriceGross',
        'deliveryPriceGross',
        'fullPriceGross',
        'fullPriceNet',
        'addedTax19',
    ];

    connect () {
        this.calculate();
    }

    async calculate () {
        clearTimeout(this.defer);
        this.element.ariaBusy = 'true';

        const formData = new FormData(this.element.closest('form'));
        const response = await fetch('/gsp_prices', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
            },
            body: formData
        });

        const data = await response.json();

        Object.keys(data).forEach(key => {
            if (this[`has${String(key).charAt(0).toUpperCase() + String(key).slice(1)}Target`]) {
                let value = data[key];

                if (true === value) {
                    value = 'Ja';
                } else if (false === value) {
                    value = 'Nein';
                }

                this[`${key}Targets`].forEach((target) => {
                    if (target.tagName.toLowerCase() === 'input') {
                        target.value = value;
                    } else {
                        target.innerHTML = value;
                    }
                });
            }
        });

        this.element.ariaBusy = 'false';
    }
}
